<template>
    <div class="inner-view">
        <button class="btn icon-btn go-back" @click="this.$emit('switchActiveComponent', 'vacancies')">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.829" height="21.451" viewBox="0 0 19.829 21.451">
                <g>
                    <g>
                        <path d="M6.118 1.032L2.8 4.349h8.477a8.6 8.6 0 0 1 8.552 8.551 8.653 8.653 0 0 1-8.551 8.551H1.917v-1.475h9.362a7.175 7.175 0 0 0 7.076-7.076 7.126 7.126 0 0 0-7.077-7.077H2.8L6.118 9.14l-1.032 1.032L0 5.086 5.086 0z" transform="translate(164) translate(-164)"/>
                    </g>
                </g>
            </svg>
        </button>
        <div class="application-overview-header">
            <h3>{{ `${vacancy.title} ${$t('Applications')}` }}</h3>
            <span class="rounded-badge">{{ candidates.length }}</span>
        </div>
        <div class="row">
            <div class="col-6" v-for="candidate in candidates" :key="candidate.id">
                <CandidateItem :candidate="candidate" @getCandidate="loadApplication(candidate.application_id)" />
            </div>
        </div>
    </div>
</template>

<script>
import CandidateItem from "@/components/applications/CandidateItemComponent";

export default {
    props: ['candidates', 'vacancy'],
    components: {
        CandidateItem
    },
    methods: {
        loadApplication(id) {
            this.$emit('getApplication', id);
        }
    }
}
</script>